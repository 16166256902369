import React from 'react'
import { Link, navigate } from 'gatsby'
import { useMutation } from 'urql'
import { SearchProvider } from '../../context/search-provider'
import { StoreContext } from "../../context/store-context"
import Alert from '../../components/utils/Alert'
import { Layout } from "../../components/layout/layout"

const CreateCustomer = `
mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
        customer {
            firstName
        }
        customerUserErrors {
            code
            field
            message
        }
    }
}
`

function RegisterPage() {
    const [loading, setLoading] = React.useState(false)
    const [firstName, setFirstName] = React.useState("")
    const [lastName, setLastName] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [alert, setAlert] = React.useState(undefined)

    const [createCustomerResult, createCustomer] = useMutation(CreateCustomer)

    const { token } = React.useContext(StoreContext)

    const register = async (e) => {
        e.preventDefault()
        setAlert(undefined)
        try {
            if (firstName === '' || lastName === '' || email === '' || password === '') return

            setLoading(true)
            const user = {
                email: email,
                password: password,
                firstName: firstName,
                lastName: lastName,
                acceptsMarketing: true
            }
            const variables = { input: user }
            const { data: { customerCreate } } = await createCustomer(variables)

            if (customerCreate.customerUserErrors.length) {
                const [firstError] = customerCreate.customerUserErrors
                throw new Error(firstError.message)
            }
            else {
                setLoading(false)
                setAlert({
                    type: 'success',
                    message: 'Successfully registered!',
                    link: '/account/login',
                    linkText: 'Sign in here'
                })
            }
        } catch (error) {
            setLoading(false)
            setAlert({
                type: 'error',
                message: error.message,
                link: '',
                linkText: ''
            })
            console.error(error)
        }
    }

    React.useEffect(() => {
        if (token?.accessToken) navigate('/account')
    }, [token])

    return (
        <Layout>
            <div className="hero w-full bg-primary text-white mx-auto flex flex-col sm:flex-col items-center justify-center py-20 px-5 relative">
                <div className="text-4xl font-bold w-full text-center">
                    <Link to="/account/register/" title="Create Account">
                        <h1 className="leading-tight font-normal text-45xl">Create Account</h1>
                    </Link>
                </div>
            </div>

            { alert && <div className='pt-4 px-4'><Alert type={alert.type} msg={alert.message} link={alert.link} linkText={alert.linkText} /></div> }

            <div className="form-body flex flex-col mb-4 w-full mx-auto py-4 px-4 max-w-2xl relative">
                {
                    loading && (
                        <div className="absolute bg-background-overlay left-0 top-0 w-full h-full flex items-center justify-center z-50">
                            <svg className="animate-spin h-6 w-6 bg-transparent border-2 border-gray-sm border-t-transparent rounded-full" viewBox="0 0 24 24"></svg>
                        </div>
                    )
                }

                <form action="" method="post" onSubmit={e => register(e)}>
                    <div className="form-group mb-4">
                        <label htmlFor="fname" className="block mb-1 uppercase">First Name</label>
                        <input
                            type="text" 
                            name="fname" 
                            id="fname" 
                            className="bg-white focus:outline-none focus:shadow-outline border border-gray-400 focus:border-gray-600 py-4 px-6 block w-full appearance-none leading-normal" 
                            required
                            onChange={e => setFirstName(e.target.value)}
                        />
                    </div>
                    <div className="form-group mb-4">
                        <label htmlFor="lname" className="block mb-1 uppercase">Last Name</label>
                        <input
                            type="text" 
                            name="lname" 
                            id="lname" 
                            className="bg-white focus:outline-none focus:shadow-outline border border-gray-400 focus:border-gray-600 py-4 px-6 block w-full appearance-none leading-normal" 
                            required
                            onChange={e => setLastName(e.target.value)}
                        />
                    </div>
                    <div className="form-group mb-4">
                        <label htmlFor="email" className="block mb-1 uppercase">Email</label>
                        <input
                            type="text" 
                            name="email" 
                            id="email" 
                            className="bg-white focus:outline-none focus:shadow-outline border border-gray-400 focus:border-gray-600 py-4 px-6 block w-full appearance-none leading-normal" 
                            required
                            onChange={e => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="form-group mb-4">
                        <label htmlFor="password" className="block mb-1 uppercase">Password</label>
                        <input
                            type="password" 
                            name="password" 
                            id="password" 
                            className="bg-white focus:outline-none focus:shadow-outline border border-gray-400 focus:border-gray-600 py-4 px-6 block w-full appearance-none leading-normal"
                            required
                            onChange={e => setPassword(e.target.value)}
                        />
                    </div>

                    <div className="button-group mt-4">
                        <button
                            type="submit"
                            className="button w-full sm:w-auto items-center justify-center bg-primary border border-primary text-white py-4 px-8 uppercase text-sm tracking-widest font-bold"
                        >
                            <span>Create</span>
                        </button>
                        <div className="flex flex-col items-center justify-center sm:block mt-4 sm:mt-4">
                            <div className='mb-4'>
                                <span className='mr-2'>Already have an account?</span> 
                                <Link to="/account/login/" className="text-center text-primary underline mb-4 font-normal">
                                    Sign in here
                                </Link>
                            </div>
                            <div>
                                <span className="mx-2">or</span>
                                <Link to="/" className="underline font-normal text-primary">Return to Store</Link>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default function RegisterPageTemplate(props) {
    return (
        <SearchProvider>
            <RegisterPage {...props} />
        </SearchProvider>
    )
}